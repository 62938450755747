// Step 1: Import React
import * as React from 'react'
import Layout from '../components/layout'
import { container } from '../components/layout.module.css'
import { Link } from 'gatsby';
import { StaticImage } from "gatsby-plugin-image"

// Step 2: Define your component
const Learn3 = () => {
  return (
    <div className={container}>
      <main>
        <Layout>

          <StaticImage src="../../static/3-learn.jpg" alt="Get Into Investing!" layout="constrained"/>

          <table align="center" width="90%">
            <tr>
              <td>
              <div><br />
                <p>
                Another important lesson that <i>Get Into Investing!</i> teaches is how to safeguard savings. Some investors may not want to put all their savings into the hands of market volatility. 
                </p>
                <p>
                Putting away some funds for safeguarding and keeping away from market volatility is vital thinking in the investment community, but only <i>Get Into Investing!</i> shows you how to quickly analyze the information on your trading account to understand your financial goals with very little math involved. 
                </p>
                <p>
                With easy to use tools on the websites of many trading platforms, it's easy to see financial products and track them in one application. You may have worked hard for a while and want to only put a little into the market for the return possibilities, so <i>Get Into Investing!</i> teaches how to sort your financial portfolio into sections; based on risk (of course). 
                </p>
                <p>
                Some that are just learning about investing in the market may want to learn how to safeguard funds for a rainy day, while still earning more than a traditional savings account. <i>Get Into Investing!</i> teaches how to assess investments for long-term and short term gain, while keeping your goals in sight. 
                </p>
                <p>
                What are you waiting for? It's time to <i>Get Into Investing!</i>
                </p>
              </div>
              <div align="center">
                    <p>
                      <>  <Link className="btn btn-outline-secondary" to="/learn4"> Learn More </Link> </>
                      <>  <Link className="btn btn-outline-secondary" to="/buy"> Buy Literature </Link> </>
                      <>  <Link className="btn btn-outline-secondary" to="/social"> Social Media </Link> </>
                    </p>
                  </div>
              </td>  
            </tr>
          </table>

        </Layout>
      </main>
    </div>
  )
}

// Step 3: Export your component
export default Learn3